/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import PlusImage from "../../resources/plus.png";

export default class ImageButton extends Component {
  render() {
    let { label, link, addLink, image, newtab } = this.props;
    return (
      <div className="LinkButtonContainer">
        {link && (
          <button
            onClick={(e) => {
              if (newtab) {
                window.open(link, "_blank");
              } else {
                if (this.props.history) {
                  this.props.history.push(link);
                } else {
                  window.location.href = link;
                }
              }
            }}
            className={addLink ? "LinkButton LinkButtonPlusLeft" : "LinkButton"}
          >
            <img src={image} alt="" />
            <label>{label}</label>
          </button>
        )}
        {addLink && typeof addLink === "string" && (
          <button
            onClick={(e) => {
              if (newtab) {
                window.open(link, "_blank");
              } else {
                if (this.props.history) {
                  this.props.history.push(addLink);
                } else {
                  window.location.href = addLink;
                }
              }
            }}
            className={
              "LinkButton LinkButtonPlusRight" +
              (link ? "" : " LinkButtonAddOnly")
            }
          >
            <img src={PlusImage} alt="" />
            {!link && label && <label>{label}</label>}
          </button>
        )}
        {addLink && typeof addLink === "function" && (
          <button onClick={addLink} className="LinkButton LinkButtonPlusRight">
            <img src={PlusImage} alt="" />
          </button>
        )}
      </div>
    );
  }
}
