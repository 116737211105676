import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./components/homePage";
import StaPage from "./components/staPage";

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <main className="container">
          <BrowserRouter>
            <Routes>
              <Route
                path={`${process.env.PUBLIC_URL}/sta`}
                element={<StaPage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/`}
                element={<HomePage />}
              />
            </Routes>
          </BrowserRouter>
        </main>
      </React.Fragment>
    );
  }
}
